import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d244b42"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "text-sm fw-bolder text-black m-0" }




export default {
  props: {
  name: String,
  url: String,
  icon: String,
},
  setup(__props) {

const props = __props



return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("a", {
    href: __props.url,
    target: "_blank",
    class: "icon-wrapper rounded-pill"
  }, [
    _createElementVNode("i", {
      class: _normalizeClass([__props.icon, "fs-2 rounded-circle p-3"]),
      style: {"background":"rgba(0, 175, 39, 0.05)","color":"rgba(0, 175, 39, 1)"}
    }, null, 2),
    _createElementVNode("p", _hoisted_2, _toDisplayString(__props.name), 1)
  ], 8, _hoisted_1))
}
}

}